// Fluid or Static mode? (percentage or pixel column widths
$gridstrap-fluid: true !default;

// Column/Gutter values
$gridstrap-cols:   12   !default;
$gridstrap-gutter: 30px !default;
$gridstrap-margin: $gridstrap-gutter !default;

// Container width values
$gridstrap-container-sm: 750px  !default;
$gridstrap-container-md: 970px  !default;
$gridstrap-container-lg: 1170px !default;

// Span and Modifer class options
$gridstrap-use-span:   true  !default;
$gridstrap-use-of:     true  !default;
$gridstrap-use-colof:  true  !default;
$gridstrap-use-offset: true  !default;
$gridstrap-use-push:   true  !default;
$gridstrap-use-pull:   true  !default;
$gridstrap-use-rtl:    false !default;
