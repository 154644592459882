.site-footer{
	color: #fff;
	background: $dark;
	padding: 60px 0;

	a {
		color: $blue;
		&:hover {
			color: $red;
		}
	}

	&:before {
		background: $dark;
	}
	&:after {
		display: none;
	}

	.social{
		@include clearfix;;
		margin: 0 0 30px;
		font-size: 0;

		a{
			@extend %with-icon, .inline-block;
			width: 36px;
			height: 36px;
			border: 2px solid;
			line-height: 36px;
			text-align: center;
			margin: 0 5px 0 0;
			transform: rotate(45deg) translateX(5px) translateY(-5px);

			&:before{
				display: block;
				font-size: 18px;
				text-indent: 3px;
				transform: rotate(-45deg);
			}
		}
	}
		.twitter:before{content:$icon-twitter}
		.facebook:before{content:$icon-facebook}
		.instagram:before{content:$icon-instagram}
		.email:before{content:$icon-email}

	.copyright{
		font-size: 16px;

		nav{
			display: inline;
		}
	}

	.credits{
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;

		@include xs-screen {
			margin-top: 30px;
		}
		
		@include sm-screen-min {
			text-align: right;
		}

		img {
			width: 125px;
			display: inline-block;
			margin-bottom: 1rem;
		}
		a{
			border: none;
		}

		small{
			font-size: 10px;
		}
	}
}
