.menu {
  text-align: right;

  &-nav {
    display: inline-block;
    padding: 10px 0;
    font-weight: bold;

    &-link {
      font-size: 16px;
      line-height: 1em;
      color: $white;
      &:hover,
      &.current{
        color: $red;
      }
    }

    &--links{
      display: none;
    }
  }

  &-button {
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
    border-radius: 2px;
  }

  &-toggle {
    display: inline-block;
    cursor: pointer;
    background: $white;
    color: $red;
    cursor: pointer;

    &:hover {
      background: $white;
      color: darken($red,10%);
    }

    &.is-active {
      box-shadow: none;
      background: darken($white,10%);
      color: $dark;
    }
  }

}

@include xs-screen {
  .menu {
    padding-right: 10px;
    &-button {
      text-align: right;
      &:last-of-type {
        margin-left: 8px;
      }
    }

    &-content{
      position: absolute;
      top: 50px;
      right: 0;
      display: none;
      padding: 0 15px;
      width: 100%;
      background: $dark;
      box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
      text-align: right;

      &.is-open{
        display: block;
      }
    }

    &-nav {
      display: block;
      padding: 10px 0;
      font-weight: bold;
      font-size: 0;

      &-link {
        display: block;
        padding: 10px 0 10px 15px;
        font-size: 16px;
        line-height: 1em;
      }

      &--links{
        border-top: 1px solid #e84960;

        .menu-nav-link {
          font-weight: normal;
        }
      }
    }
  }
}


@media screen and (max-width: 989px) {
  .menu {
    &-button {
      margin-top: 6px;
      padding: 10px 12px;
    }
  }
}

@include sm-screen-min {
  .menu {
    &-content {
      display: inline-block;
    }
    &-toggle {
      display: none;
    }
    &-tickets {
      float: right;
      margin-left: 20px;
    }
    &-nav {
      float: right;
      letter-spacing: .08em;
      &-link {
        font-size: 14px;
        position: relative;
        margin-left: 10px;

        &:hover {
          color: $white;
          &:after {
            transform: scaleX(1);
          }
        }

        &.current{
          color: $white;
          &:after {
            transform: scaleX(1);
            background-color: $white;
          }
        }

        &:after {
  				content: "";
  			  position: absolute;
  			  width: 100%;
  			  height: 2px;
  			  bottom: 0;
  			  left: 0;
  			  background-color: $red;
  			  visibility: visible;
  			  transform: scaleX(0);
  			  transition: all 0.3s ease-in-out 0s;
          transform-origin: top left;
  			}
      }
    }
  }
}


@include md-screen-min {
  .menu {
    &-nav {
      padding: 40px 0;

      &-link {
        font-size: 16px;
      }
    }
    &-button {
      margin-top: 32px;
      margin-left: 30px;
    }
  }
}

@include lg-screen {
  .menu {
    &-tickets {
      min-width: 140px;
    }

    &-button {
      margin-left: 60px;
    }
  }
}

@include xl-screen {
  .menu {
    &-nav {
      padding: 45px 0;
    }

    &-button {
      margin-top: 36px;
    }
  }
}
