.sneak-edit {
  box-shadow: 0 2px 6px rgba(0,0,0,0.3);
  border-radius: 2px;
  padding: 8px 16px 8px 16px;
  position: fixed;
  bottom: 12px;
  left: 12px;
  line-height: 22px;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  transition: all .15s ease-out;
  color: #748885;
  z-index: 20000;
  background: white;

  &:hover {
    text-decoration: none;
    color: #3aa3e3;
    background: white;
  }
}
