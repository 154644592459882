.schedule-nav{
	padding: 15px 0;
	position: relative;
	z-index: 2;
	margin-bottom: 30px;
	margin-top: -70px;
	background: transparent;

	@include sm-screen-min {
		margin-top: -100px;
		margin-bottom: 50px;
	}
}
	.day-toggle{
		background: $white;
		color: $red;
		letter-spacing: none;
		padding: 15px;
		margin: 0 15px 0 0;
		text-transform: uppercase;
		font-weight: normal;
		border: 1px solid $red;
		box-shadow: none;

		&:last-child {
			margin-right: 0;
		}

		@include sm-screen-min {
			display: inline-block;
			width: 31%;
		}

		@include md-screen-min {
			font-size: 1.5rem;
		}

		&:hover,
		&.current{
			background: #fff;
			color: $red;
			box-shadow: none;
		}

		&.current{
			color: $white;
			background: $red;
		}
	}

.schedule-day{
	h2{
		font-size: 30px;
		margin: 0;

		small {
			font-size: 20px;
			font-weight: normal;

			&:before{
				content: '/ ';
				font-size: 30px;
				font-weight: bold;
			}
		}

		@include md-screen-min {
			font-size: 2em;

			small {
				font-size: .75em;
			}
		}
	}
}

.schedule-events{
	tr{
		background: rgba(#000, 0);
		transition: background .2s;

		&:hover{
			background: rgba(#000, .025);
		}
	}

	.event-time{
		width: 200px;
		font-size: 18px;
		padding-left: 10px;
		padding-right: 30px;
	}
	.event-image{
		width: 70px;

		.photo {
			border-width: 5px;
		}

		img{
			display: block;
			width: 100%;
			height: auto;
		}
	}
	.event-info{
		font-size: 26px;
		padding-left: 30px;
		padding-right: 10px;

		a{
			display: block;
			color: #000;
			border: none;
		}

		&-title{
			font-size: 16px;
			margin: 0;
			text-transform: uppercase;
		}
		&-subtitle{
			color: $red;
			margin: 0;
			@extend %condensed;
			font-weight: 700;
		}
	}
}
