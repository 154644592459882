.location {

  &-item {
    margin-bottom: 4.5em;
  }

  &-title {
    font-weight: 300;
    text-transform: none;
    margin-bottom: .2em;

    @include md-screen-min {
      font-size: 2em;
    }
  }

  &-address {
    display: block;
    font-size: .9em;
    font-family: "Open sans", sans-serif;
    font-weight: bold;
    color: $red;
  }

  &-photo {
    margin: 1.5rem -10% 1.5rem -10%;
    display: block;
    max-width: 120%;

    @include md-screen-min {
      margin: 1.5rem -12% 1.5rem -12%;
      width: 124%;
      max-width: none;
    }
  }

  &-content {
    font-family: "Open sans", sans-serif;
  }
}
