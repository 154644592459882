@import 'mobile';

@mixin breakpoint-sm{
	.clear-sm{clear: both;}

	.speakers{
		.speaker:nth-child(3n-5){
			clear: both;
		}
	}

	.menu-wrapper{
		font-size: 16px;
	}

	#topbar {
		.logo {
			top: -16px;
			width: 320px;
			height: 138px;
		}
	}
}

@mixin breakpoint-md{
	.clear-sm{clear: none;}
	.clear-md{clear: both;}

	.breadcrumbs{
		&:before,
		&:after{
			content: '';
			position: absolute;
			top: 0;
			right: 50%;
		}

		&:before{
			width: 50%;
			height: 100%;
			margin-right: 425px;
			background: #000;
		}

		&:after{
			margin-right: 375px;
			border: 25px solid transparent;
			border-color: #000 transparent transparent #000;
		}

		.container{
			&:before{
				content: '';
				position: absolute;
				top: 14px;
				left: 40px;
				width: 20px;
				height: 22px;
				background: url(../img/dnc-logo-bug.svg) center center no-repeat;
			}
		}
	}

	.speakers{
		.speaker:nth-child(3n-5){
			clear: none;
		}
		.speaker:nth-child(4n-7){
			clear: both;
		}
	}
}

@mixin breakpoint-lg{
	.clear-md{clear: none;}
	.clear-lg{clear: both;}

	.breadcrumbs{
		&:before{
			margin-right: 525px;
		}

		&:after{
			margin-right: 475px;
		}

		.container{
			&:before{
				left: 50px;
			}
		}
	}
}
