@font-face {
	font-family: 'designcontentconf-icons';
	src: url('../font/designcontentconf-icons/designcontentconf-icons.eot');
	src: url('../font/designcontentconf-icons/designcontentconf-icons.eot#iefix') format('embedded-opentype'),
		 url('../font/designcontentconf-icons/designcontentconf-icons.woff') format('woff'),
		 url('../font/designcontentconf-icons/designcontentconf-icons.ttf') format('truetype'),
		 url('../font/designcontentconf-icons/designcontentconf-icons.svg#designcontentconf-icons') format('svg');
	font-weight: normal;
	font-style: normal;
}

%icon{
	font-family: 'designcontentconf-icons';
	font-weight: normal !important;
	font-style: normal !important;
}

%with-icon{
	font: 0/0 a;
	display: inline-block;

	&:before{
		@extend %icon;
	}
}


.icon-play {
	.icon-accent {
		fill: $red;
		transition: all .15s;
	}

	&:hover {
		.icon-accent {
			fill: $red;
			mix-blend-mode: normal !important;
		}
	}
}

$icon-twitter: '\e800';
$icon-facebook: '\e801';
$icon-email: '\e802';
$icon-link: '\e803';
$icon-phone: '\e804';
$icon-book: '\e805';
$icon-instagram: '\e806';
