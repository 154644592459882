.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.text-justify {
	text-align: justify;
}

// For mutli-directional stylesheets
@if ($handysass-enable-rtl) {
	.text-norm {
		.ltr & {
			@extend .text-left;
		}

		.rtl & {
			@extend .text-right;
		}
	}

	.text-alt {
		.ltr & {
			@extend .text-right;
		}

		.rtl & {
			@extend .text-left;
		}
	}
}