@mixin ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin inline-block {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin hide-text() {
  $approximate-em-value: 12px / 1em;
  $wider-than-any-screen: -9999em;
  text-indent: $wider-than-any-screen * $approximate-em-value;
  overflow: hidden;
  text-align: left;
}

@mixin aspect-ratio($width, $height) {
  position: relative;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .fill {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin block {
  &:not(:last-child) {
    margin-bottom: 20px
	}
}


// Large (Widescreen) and up
@mixin xl-screen {
	@include screen-size($min: $xl-screen) {
		@content;
	}
}
