.photo{
	display: block;
	border: 10px solid #fff;
	margin: 0 0 10px;
	position: relative;
	@include clearfix;

	img{
		display: block;
		width: 100%;
		height: auto;
	}

	&.square{
		position: relative;
		height: 0;
		padding-bottom: 100%;
		background: #f8f8f8;
	}

	&.rectangle{
		padding-bottom: 50%;
	}

	&.rhombus {
		position: relative;
		border: 0;
		background-color: #fff;
		padding: 6px;
		overflow: hidden;
	}

	&.multiple{
		position: relative;

		.image {
			float: left;
			width: 50%;
			overflow: hidden;

			img {
				width: 200%;
				transform: translateX(-25%);
			}
		}
	}
}

// Links
a.photo,
.photo > a {
	overflow: hidden;
	img {
		transition: all .3s ease-out;
	}

	&:hover img {
		transform: scale(1.2);
	}

	&.bg-primary {
		&:hover img {
			opacity: .35;
			filter: grayscale(100%);
		}
	}
}

a.photo.bg-primary,
.photo.bg-primary > a {
	&:hover img {
		opacity: .35;
		filter: grayscale(100%);
	}
}

	.frame{
		position: absolute;
		top: 30px;
		left: 30px;
		bottom: 30px;
		right: 30px;
	}

.breadcrumbs{
	background: $red;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: .05em;
	text-transform: uppercase;
	position: relative;

	&, a{
		color: #fff;
	}

	a{
		border: none;

		&:hover{
			color: #000;
		}
	}

	.container{
		padding-top: 18px;
		padding-bottom: 18px;
		z-index: 1;
	}
}

.type{
	@extend %sans;
	color: #e8e8e8;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: .1em;
	text-transform: uppercase;
	margin: 0 0 10px;

	em{
		color: #000;
		font-style: normal;
	}
}

.tickets{

	th, td{
		padding-left: 0;
		padding-right: 0;
	}
	em{
		color: $red;
		font-size: .8em;
		font-style: normal;
		font-weight: bold;
		text-transform: uppercase;
	}
	.soldout{
		.ticket-name{
			strong{
				text-decoration: line-through;
			}
			&:after{
				content: ' SOLD OUT';
				font-size: .8em;
				color: $red;
				font-weight: bold;
				text-decoration: none;
			}
		}
	}
	.ticket-price {
		text-align: center;
	}
}

.box{
	display: block;
	color: #000;
	background: #fff;
	margin: 0 0 30px;
	padding: 30px 0;
	text-align: center;
	white-space: nowrap;
	box-shadow: 0 2px 1px rgba(#c5c5c5, .75);

	.icon{
		@extend %with-icon;
		display: block;
		color: $red;
		font-size: 32px;
		width: 36px;
		height: 36px;
		line-height: 36px;
		margin: 0 auto 20px;
	}

	.title{
		display: block;
		color: #000;
		text-transform: uppercase;
		font-size: 20px;
		font-weight: bold;
		margin: 0 0 15px;

		&:after{
			content: '';
			display: block;
			border-bottom: 2px solid;
			width: 40px;
			margin: 5px auto;
		}
	}

	&:hover{
		color: $blue;
	}

	&.email .icon:before{content: $icon-email;}
	&.phone .icon:before{content: $icon-phone;}
	&.twitter .icon:before{content: $icon-twitter;}
	&.facebook .icon:before{content: $icon-facebook;}
	&.book .icon:before{content: $icon-book;}
}

.page-skew {
	transform: skewY(-5deg);
	height: 500px;
	.container {
		transform: skewY(5deg);
	}
}
