.widget {
  margin: 0 0 3em;
  &-title {
    text-transform: none;
    margin-bottom: .5em;
  }

  .stButton .stBubble_count { height: 41px; }
  .stButton .stMainServices { height: 22px; }
}
