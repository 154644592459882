// Form
// ––––––––––––––––––––––––––––––––––––––––––––––––––

input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
textarea,
select {
	appearance: none; // Removes awkward default styles on some inputs for iOS
	background-color: transparent;
	border: 1px solid $border;
	box-shadow: none;
	border-radius: 0;
	box-sizing: inherit; // Forced to replace inherit values of the normalize.css
	height: 2.5em;
	padding: .4em .6em; // The .6rem vertically centers text on FF, ignored by Webkit
	width: 100%;

	&:focus {
		border-color: $red;
		outline: 0;
	}
}

input.mce_inline_error {
	border-color: $red;
}

input.valid, input.valid:focus {
	border-color: $green;
}

input, textarea, select, fieldset {
  margin-bottom: 1.5em;
}
select {
	background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="' + $border + '" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>') center right no-repeat;
	padding-right: 1.5em;
	&:focus {
		background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="' + $red + '" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>');
	}
}
textarea {
	min-height: 9em;
	resize: none;
}
label,
legend {
	display: block;
	font-weight: 300;
	margin-bottom: .5em;
}
fieldset {
	border-width: 0;
	padding: 0;
}
input[type='checkbox'],
input[type='radio'] {
	display: inline;
}
input[type='submit'], button {
	cursor: pointer;
}
.label-inline {
	display: inline-block;
	font-weight: normal;
	margin-left: .5em;
}
.input-help, div.mce_inline_error {
	display: block;
	margin-top: -1.2em;
	margin-bottom: 1.5em;
}

div.mce_inline_error {
	color: $red;
}

.honeypot {
	display: none;
}
.fieldset-title {
	text-transform: uppercase;
	font-weight: 300;
	margin-top: 1em;
}
