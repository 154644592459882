.button{
	@extend .inline-block;
	color: #fff;
	//font-size: 16px;
	font-weight: bold;
	line-height: 1em;
	text-align: center;
	padding: 15px 30px;
	background: $red;
	border: none;
	transition: all .15s;
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.36);

	&:hover{
		box-shadow: 0 6px 20px rgba(0,0,0,.36);
		background: mix($purple,$red,50%);
		color: $white;
	}

	&.white {
		background: #FFF;
		color: darken($red,15%);
		&:hover{
			color: $purple;
			* {
				color: $purple;
			}
		}
	}

	&--flat {
		box-shadow: none;
		&:hover {
			box-shadow: none;
		}
	}
	&.big {
		font-size: 1em;
	}

	@include md-screen-min {
		&.big{
			font-size: 24px;
			padding: 15px 45px;
		}
		&--wide {
			width: 100%;
		}
	}
}



.register-button{
	margin: 0 0 10px;
}
