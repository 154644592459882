%sans{
	font-family: 'Open Sans', sans-serif;
}
.sans{@extend %sans;}

%condensed{
	font-family: 'Open Sans Condensed', sans-serif;
}
.condensed{@extend %condensed;}

%serif{
	font-family: serif;
	font-weight: normal;
	text-transform: none;
}
.serif{@extend %serif;}

html{
	background: $dark;
}

body, input, select{
	@extend %condensed;
	font-size: 20px;
}

a{
	color: $red;
	text-decoration: none;
	transition: color .2s;

	&.link-blue {
		color: $blue;

		&:hover{
			color: $red;
		}
	}

	&:hover{
		color: $blue;
	}

	h2 &{
		color: #000;
		text-decoration: none;
	}

	&:focus,
	&:active{
		outline: none;
	}
}

h1, h2, h3, h4, h5, h6{
	@extend %condensed;
	line-height: 1.2em;
	text-transform: uppercase;
	font-weight: 300;
}

h1, h2, h3{
	small{
		font-size: .75em;
	}
}

h1, .h1{
	font-size: 40px;
	margin: 0 0 .5em;
}
h2, .h2{
	font-size: 30px;
	font-weight: 700;
	margin: 0 0 .75em;
}
h3, .h3{
	font-size: 24px;
	font-weight: 700;
	margin: 0 0 .25em;
}
h4,h5,h6{
	font-size: 18px;
}

p, ul, ol{
	line-height: 1.4em;
	margin: 0 0 1.4em;
	@extend %sans;

	a {
		font-weight: bold;
	}
}



.lead{
	@include md-screen-min {
		line-height: 1.4;
		font-size: 1.25em;
		margin: 0 0 2em;
	}
}

.small {
	font-size: 1rem;
}

// ul li {
// 	list-style: none;
// 	position: relative;
//
// 	&:before{
// 		content: '';
// 		position: absolute;
// 		transform: rotate(45deg);
// 		border: 4px solid;
// 		top: 9px;
// 		left: -22px;
// 	}
// }

hr{
	border: none;
	border-bottom: 1px solid #000;
	margin: 30px 0;
}

table{
	border-collapse: collapse;
	width: 100%;
	margin: 15px 0 50px;
	font-size: inherit;

	th, td{
		border-collapse: collapse;
		border: none;
		padding: 20px 0;
		border-top: 1px solid $black;
		border-bottom: 1px solid $black;
		vertical-align: middle;
		float: none !important;
	}

	th{
		font-weight: bold;
		text-transform: uppercase;
		border-top: none;
	}
}

blockquote {
	border-left: 3px solid #ccc;
	font-size: 1.25em;
	font-style: italic;
	padding: 0 1em;

	p {
		line-height: 1.4em;
	}
}

strong{
	font-weight: bold;
}

em{
	font-style: italic;
}

del{
	text-decoration: line-through;
}

.lipsum{
	color: #FFFF00;
}
