.item-nav{
	@include clearfix;;
	margin: -45px 0 45px;
	border: 2px solid #fff;
	text-transform: uppercase;

	a{
		float: left;
		border: 2px solid #fff;
		position: relative;
		overflow: hidden;

		&:before,
		&:after{
			content: '';
			position: absolute;
			z-index: 1;
		}

		&:before{
			background: rgba(#fff, .8);
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			transition: opacity .2s;
		}
	}
		img{
			display: block;
			width: 100%;
			height: auto;
		}
		span{
			position: absolute;
			bottom: 0;
			left: 0;
			padding: 5px;
			font-size: 12px;
			font-weight: bold;
			color: #000;
			z-index: 2;
			opacity: 0;
			transition: opacity .2s;
		}

	a:hover{
		span{
			opacity: .3;
		}
	}

	a:hover,
	.current{
		&:before{
			opacity: 1;
		}
	}

	.current{
		&:after{
			top: 50%;
			left: 50%;
			margin: -70px 0 0 -70px;
			border: 70px solid;
			border-color: rgba(#000, .1) rgba(#000, .0) rgba(#000, .2) rgba(#000, .3);
		}
	}
}