.message-body {
  border: 1px solid $border;
  padding: 12px 15px;
  strong {
    color: inherit;
  }
}
.message-header {
  background-color: $text;
  color: $text-invert;
  padding: 7px 10px;

  strong {
    color: inherit;
  }
  & + .message-body {
    border-top: none;
  }
}

.message {
  @include block;
  background-color: hsl(0, 0%, 96%);
  // Colors
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    $lightning: max((100% - lightness($color)) - 4%, 0%);
    $darkness: max(lightness($color) - 10%, lightness($color));
    &.is-#{$name} {
      background-color: lighten($color, $lightning);
      .message-header {
        background-color: $color;
        color: $color-invert;
      }
      .message-body {
        border-color: $color;
        @if (colorLuminance($color) > 0.8) {
          color: desaturate(lighten(darken($color, 100%), 40%), 40%);
        } @else {
          color: desaturate(lighten(darken($color, 100%), 50%), 30%);
        }
      }
    }
  }
}
