.pull-left {
	float: left;
}

.pull-right {
	float: right;
}

// For mutli-directional stylesheets
@if ($handysass-enable-rtl) {
	.pull {
		.ltr & {
			float: left;
		}

		.rtl & {
			float: right;
		}
	}

	.pull-alt {
		.ltr & {
			float: right;
		}

		.rtl & {
			float: left;
		}
	}
}