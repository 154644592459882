.tag {
  align-items: center;
  background-color: $red;
  border-radius: 290486px;
  color: $text-invert;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 16px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  vertical-align: top;
  white-space: nowrap;
  transition: all .15s;
	box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.0);

	&:hover{
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.36);
		background: mix($purple,$red,50%);
		color: $text-invert;
	}

  // Colors
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name} {
      background-color: $color;
      color: $text-invert;

      &:hover{
    		background: mix($purple,$color,50%);
    	}
    }
  }

  font-size: 20px;
  height: 32px;
  padding-left: 14px;
  padding-right: 14px;
}

.tag-list {
  margin-bottom: 32px;
}
