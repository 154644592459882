.bg-primary {
  background: $red linear-gradient(-180deg, $red 0%, $purple 100%);
  * { color: $white; }

  &.edge-both {
    background: #BD3494;

    &:before {
      background: #BD3494 linear-gradient(-180deg, $red 0%, $purple 100%);
    }
    &:after {
      background: #BD3494 linear-gradient(-180deg, $purple 0%, $red 100%);
    }
  }

}
