.events{
	hr{
		margin: 20px 15px 50px;
	}
}

.event{
	@include clearfix;;

	.summary{
		small{
			display: block;
			font-size: 14px;
			font-weight: bold;
			letter-spacing: .1em;
			text-transform: uppercase;
			margin: 0;
		}

		h2{
			//@extend %serif;
			font-size: 30px;
			line-height: 34px;
			margin: 0 0 20px;

			a{
				color: #000;
				border: none;
			}
		}

		.button{
			font-size: 16px;
		}
	}

	.events &{
		margin-bottom: 0;
	}
}

.talk,
.post-type-archive-talk{
	.button{
		background: $blue;

		&:hover{
			background: $black;
			color: $blue;
		}
	}
}

.event-preview.talk .button{
	color: $blue;

	&:hover{
		background: $blue;
		color: #fff;
	}
}

.talk{
	.summary{
		h2 a:hover{
			color: $blue;
		}
	}
}

.workshop{
	.summary{
		h2 a:hover{
			color: $red;
		}
	}
}

.event-preview{
	text-align: left;
	margin: 0 0 10px;

	.button{
		clear: both;
		display: block;
		text-align: left;
		padding: 10px;
		margin: 0 0 10px;
		background: #fff;

		small{
			display: block;
			font-size: 12px;
			line-height: 1em;
		}
	}
}
