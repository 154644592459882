.speaker, .about {
	.page-banner {
		padding-bottom: 80px !important;


		&-twitter {
			display: inline-block;
			font-weight: bold;
			margin-bottom: 80px;
			@extend %condensed;
			p {
				@extend %condensed;
			}

			@include sm-screen-min {
				margin-top: 80px;
				margin-bottom: 0;
			}
		}
	}

	.photo {
		margin-top: -180px;
		max-width: 300px;
		@include xs-screen {
			max-width: 200px !important;
		}
	}

	.event {
		&-title {
			color: $red;
			font-weight: 300;
			&:hover {
				color: $blue;
			}
		}
	}
}
