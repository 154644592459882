.home {
	.page-banner {
		.contents {
			padding-bottom: 45px;

			@include sm-screen-min {
				padding-bottom: 95px;
			}
		}

		@include xs-screen {
			display: block;
		}

		&-title {
			font-size: 1.25em;
			max-width: 15em;

			@include sm-screen-min {
				font-size:2.5em;

				.page-banner-title-intro {
					font-size: .6em;
				}
			}

			@include xl-screen {
				font-size:3.5em;
			}
		}
	}
}


#home-banner{
	background:  center top no-repeat;
	height: 1080px;
	color: #fff;
	font-weight: bold;
	text-align: center;
	position: relative;

	.headerbg{
		border: none;
		border-bottom: 70px solid $red;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		margin: 0;
	}

	.cover{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		background: url(../img/v-cover.png) center bottom no-repeat;

		&:before,
		&:after{
			content: '';
			position: absolute;
			top: 0;
			height: 100%;
			width: 50%;
		}

		&:before{
			right: 50%;
			margin-right: 1680px * .5;
			background: $black url(../img/tile.png);
		}

		&:after{
			left: 50%;
			margin-left: 1680px * .5;
			background: #fff;
		}
	}

	header{
		position: relative;
		z-index: 1;
		text-transform: uppercase;
		color: $white;
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 0.08em;
		line-height: 20px;

		.container{
			padding-top: 25px;
			padding-bottom: 25px;
		}

		.diamond{
			content: '';
			position: absolute;
			z-index: -1;
			top: 0;
			left: 50%;
			margin-left: -262px;
			border: 262px solid transparent;
			border-top-color: $black;

			.csstransforms &{
				border: none;
				width: 370px;
				height: 370px;
				margin: -185px 0 0 -185px;
				overflow: hidden;
				background: $black;
				transform: rotate(45deg);

				&:before{
					content: '';
					position: absolute;
					top: -50%;
					left: -50%;
					width: 200%;
					height: 200%;
					background: url(../img/tile.png);
					transform: rotate(-45deg);
				}
			}
		}

		.logo{
			font: 0/0 a;
			position: absolute;
			z-index: 1;
			top: 0;
			left: 50%;
			width: 270px;
			height: 140px;
			margin-left: -135px;
			background: url(../img/dnc-logo-front.png) center 17px no-repeat;
			background-image: url(../img/dnc-logo-front.svg);
		}
	}

	.content{
		padding-top: 215px;
		position: relative;
		z-index: 1;

		h2{
			font-size: 68px;
			font-weight: bold;
			line-height: 70px;
			text-transform: uppercase;
			margin: 0 0 .5em;
		}
		p{
			font-size: 20px;
			line-height: 30px;
		}
	}

	.diagonals{
		&:before,
		&:after{
			height: 585px;
		}

		&:before{
			shape-outside: polygon(0 0, 10% 0, 100% 90%, 100% 100%, 0 100%);
		}
		&:after{
			shape-outside: polygon(100% 0, 90% 0, 0 90%, 0 100%, 100% 100%);
		}
	}
}

#speakers-summary{
	background: #f5f5f5;
	padding: 50px 0;

	.speaker{
		float: left;
		width: 20%;
		padding: 0 15px;
		margin: 0 0 50px;
		font-size: 14px;
		text-transform: uppercase;
	}
		.photo{
			position: relative;
			overflow: hidden;
			margin: 0 0 10px;

			img{
				display: block;
				max-width: 100%;
				height: auto;
			}
		}
			.twitter{
				@extend %with-icon;
				position: absolute;
				bottom: -50px;
				right: -50px;
				width: 100px;
				height: 100px;
				color: #fff;
				background: #000;
				border: none;
				transform: rotate(45deg);

				&:before{
					content: $icon-twitter;
					font-size: 16px;
					position: absolute;
					top: 50%;
					left: 10px;
					transform: rotate(-45deg);
				}
			}
		h3{
			@extend %serif;
		}
		p{
			margin: 0;
		}
}

#sponsors-summary{
	h2{
		font-size: 40px;
	}
}
