$sm-screen: 768px !default;
$md-screen: 990px !default;
$lg-screen: 1230px !default;

// Add a screen @media rule for 2x pixel ratio (retina display)
@mixin retina-screen($extra: null) {
	$rule: "only screen and (-webkit-min-device-pixel-ratio: 2)";
	@if $extra {
		$rule: $rule + " and (" + $extra + ")";
	}
	@media #{$rule} {
		@content;
	}
}

// Screen size setup.
//
// Pass the small, medium and large screen sizes to use.
// Pass 0 to use the default/previously set one.
//
// @param number $sm The small (e.g. tablet) screen width
// @param number $md The medium (e.g. laptop) screen width
// @param number $lg The large (e.g. desktop) screen width

@mixin screen-sizes($sm: 0, $md: 0, $lg: 0) {
	@if $sm != 0{
		$sm-screen: $sm;
	}
	@if $md != 0{
		$md-screen: $md;
	}
	@if $lg != 0{
		$lg-screen: $lg;
	}
}

// Add a screen @media rule for the specified min/max widths.
//
// Recommended use: keywork argument syntax, for consistency
// and for when specifying just the $max argument.
//
// @param mixed $min The min width of the screen (false to ignore).
// @param mixed $max The max width of the screen (false to ignore).

@mixin screen-size($min:false, $max:false) {
	$rule: "screen";

	@if $min and $max {
		$rule: "screen and (min-width: " + $min + ") and (max-width: " + $max + ")";
	}
	@else if $min {
		$rule: "screen and (min-width: " + $min + ")";
	}
	@else if $max {
		$rule: "screen and (max-width: " + $max + ")";
	}

	@media #{$rule} {
		@content;
	}
}

// Extra-Small (Mobile) only
@mixin xs-screen {
	@include screen-size($max: $sm-screen - 1) {
		@content;
	}
}

// Small (Tablet) and up
@mixin sm-screen-min {
	@include screen-size($min: $sm-screen) {
		@content;
	}
}

// Small (Tablet) and down
@mixin sm-screen-max {
	@include screen-size($max: $md-screen - 1) {
		@content;
	}
}

// Small (Tablet) only
@mixin sm-screen {
	@include screen-size($min: $sm-screen, $max: $md-screen - 1) {
		@content;
	}
}

// Medium (Desktop) and up
@mixin md-screen-min {
	@include screen-size($min: $md-screen) {
		@content;
	}
}

// Medium (Desktop) and down
@mixin md-screen-max {
	@include screen-size($max: $lg-screen - 1) {
		@content;
	}
}

// Medium (Desktop) only
@mixin md-screen {
	@include screen-size($min: $md-screen, $max: $lg-screen - 1) {
		@content;
	}
}

// Large (Widescreen) and up
@mixin lg-screen {
	@include screen-size($min: $lg-screen) {
		@content;
	}
}