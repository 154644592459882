@mixin banner-padding($navbar-height) {
	padding: round($navbar-height*1.75) 0 round($navbar-height*1.5) 0;
}

.page-banner{
	@include banner-padding(50px);
	position: relative;
	background: center center no-repeat;
	background-size: cover;
	color: #fff;
	overflow: hidden;

	a {
		color: #FFF;
		position: relative;
		&:hover {
			color: #FFF;
			&:before {
				opacity: 0;
			}
		}

		&:before {
			content: "";
			position: absolute;
			width: 100%;
			height: 2px;
			bottom: 0;
			left: 0;
			background-color: #FFF;
			visibility: visible;
			transition: all 0.3s ease-in-out 0s;
			opacity: 1;
		}
	}


	@include md-screen-min {
		@include banner-padding(110px);
		min-height: 400px;
	}

	&-title {
		font-weight:700;
		font-style:normal;
		letter-spacing:2px;
		text-transform: none;
		color:#fff;
		font-size: 1.25em;
		line-height:1.2;
		margin-bottom: 1.1em;

		@include sm-screen-min {
			font-size:2em;
		}

		&-intro {
			display: block;
			font-size: .8em;
			line-height: 1;
			font-weight: 300;
			text-transform:uppercase;
			margin-bottom: .5em;

			span {
				text-transform: none;
			}
			@include sm-screen-min {
				font-size:1em;
			}
		}

		&--lg {
			font-size: 1.25em;
			max-width: 15em;

			@include sm-screen-min {
				font-size:2.5em;

				.page-banner-title-intro {
					font-size: .6em;
				}
			}

			@include xl-screen {
				font-size:3.5em;
			}
		}

		em {
			font-weight: inherit;
			font-style: inherit;
			white-space: nowrap;
		}
	}

	&-author {
		display: inline-block;
		font-weight: bold;
		margin-top: 20px;
		@extend %condensed;
	}

	&--post {
		.page-banner-title {
			margin-bottom: .5em;
		}
	}
}

.page-intro {
	background: #FFF;
	color: $dark;
	padding: 25px 15px;
	text-align: left;
	font-size: 16px;

	@media only screen and (min-width: 481px) {
		padding: 35px 25px;
		font-size: 20px;
		text-align: center;
	}

	@include md-screen-min {
		padding: 50px 0 25px;
		font-size: 28px;
	}

	p{
		line-height: 1.6;
		margin: 0;
		color: $dark;
		@extend %condensed;
	}

	.button{
		font-size: 16px;
		padding: 15px 20px;

		&:hover{
			color: $blue;
			background: #fff;
		}
	}

	&--floating {
		box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.23);
		padding: 15px 15px;
		z-index: 2;
		position: relative;
		margin-top: -90px;

		@include sm-screen-min {
			padding: 15px 30px;
			margin-top: -140px;
		}
		@include md-screen-min {
			padding: 34px 60px;
			margin-top: -180px;
			margin-bottom: 50px;
		}
	}
}

.page-content{
	padding: 20px 0;
	background: #FFF;
	position: relative;

	&--spaced {
		padding: 60px 0;
	}
	img.size-large,
	img.size-full{
		display: block;
		max-width: 100%;
		height: auto;
	}

	&-title {
		font-size: 1.25em;
		line-height: 1;
		font-weight: 300;
		text-transform: none;
		margin-bottom: 1em;
	}

	ol {
		padding-left: 0;
		margin-top: -.75em;
		li {
			list-style: outside decimal;
			padding: 0 0 .25em .5em;
			margin: 0 0 .25em 1em;
		}
	}
  ol ol,
	ol ol ol {
		margin-top: .5em;
		margin-bottom: 0;
	}


	@include sm-screen-min {
		padding: 45px 0;

		&--spaced {
			padding: 90px 0;
		}

		&-title {
			font-size: 1.5em;
		}
	}

	@include md-screen-min {
		&-title {
			font-size: 2.5em;
		}
	}
}

.page-video {
	background: center center no-repeat;
	background-size: cover;
	color: #fff;
	@include aspect-ratio(16,9);
	padding: 0 !important;

	&-icon > svg {
		width: 50px;

		.icon-stroke {
			stroke-width: 12;
		}
	}

	&-title {
		font-size: 1em;
		line-height: 1;
		font-weight: 300;
		text-transform: none;
		max-width: 12em;
		margin: .5em auto;
	}

	@include sm-screen-min {
		&-icon > svg {
			width: 75px;

			.icon-stroke {
				stroke-width: 6;
			}
		}
		&-title {
			font-size: 1.5em;
		}
	}

	@include md-screen-min {
		min-height: 400px;

		&-icon > svg {
			width: 103px;
		}

		&-title {
			font-size: 2.5em;
		}
	}
}

.video-fullscreen {
	position: fixed;
  width: 100%;
  height: 100%;
  display: none;
  background: rgba(0, 0, 0, 0.9);
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
	transition: all 0.2s ease;

	iframe {
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 15;
	}

	&-close {
		display: inline-block;
    vertical-align: middle;
    height: 14px;
    width: 14px;
    background-image: url('../img/close.svg');
    background-repeat: no-repeat;
    position: absolute;
    top: 2em;
    right: 2em;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease;

		&:after {
			content: "";
			position: absolute;
			width: 2em;
			height: 2em;
			display: block;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			border-radius: 6.25em;
			transition: all 0.2s ease;
			background: rgba(255,255,255, 0.2);
		}

		&:hover {
			&:after {
				width: 2.625em;
				height: 2.625em;
			}
		}
	}



	&.is-open &-close {
		opacity: 1;
		visibility: visible;
		z-index: 120;

		&:after {
			z-index: 100;
		}
	}

	&.is-open {
		display: block;
		z-index: 100;
		visibility: inherit;
		opacity: 1;
		transform: translate3d(0px, 0px, 0px);
	}
}




	.adjacent{
		font: 0/0 a;
		position: absolute;
		top: 0;
		height: 100%;
		width: 130px;
		background: center center no-repeat;
		background-size: contain;
		z-index: 2;

		&.prev{
			left: 10px;
			background-image: url(../img/arrow-left.svg);
		}
		&.next{
			right: 10px;
			background-image: url(../img/arrow-right.svg);
		}

		@media screen and (max-width:1300px){
			width: 70px;
		}
	}
