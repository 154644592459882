$gridstrap-container-lg: 1200px;
@include gridstrap-use(span, offset);
@include gridstrap(up);

.vcenter{
	display: table;
	width: 100%;
	height: 100%;

	.contents{
		display: table-cell;
		vertical-align: middle;
	}
}

.vcenter-flex {
	display: flex;
  align-items: center;
  justify-content: center;
}

.diagonals{
	&:before,
	&:after{
		content: '';
		display: block;
		width: 50%;
	}

	&:before{
		float: left;
		shape-outside: polygon(0 0, 100% 100%, 0 100%);
	}
	&:after{
		float: right;
		shape-outside: polygon(100% 0, 0 100%, 100% 100%);
	}
}


.media {
  display: flex;
  align-items: flex-start;
	margin-bottom: 1rem;

	&-center {
		align-items: center;
	}
}

.media-figure {
  margin-right: 10px;
}

.media-body {
  flex: 1;
}

.m-b-0 {
	margin-bottom: 0 !important;
}
