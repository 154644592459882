.speakers {
	@include clearfix;;

	&-spaced {
		margin-bottom: 1em;
		overflow: hidden;
		margin-left: -15px;
	}

	.photo{
		position: relative;
		overflow: hidden;
		width: 120px;
		margin-left: -20px;
		margin-right: 15px;

		@include sm-screen-min {
			width: 225px;
			margin-left: 0;
			margin-right: 30px;
		}
	}

	&-title {
		text-transform: uppercase;
	}

	&-position {
		margin-top: 0;
	}

	&-events {
		list-style: none;
		margin: 0;
		padding: 0;
		font-weight: bold;
		@extend %condensed;
	}

	&-event-title {
		font-weight: 300;
	}
}

.speaker{
	@include clearfix;;
	margin: 0 0 20px;

	.speakers &{
		font-size: 16px;
		text-align: center;
		text-transform: uppercase;
		margin: 0 0 10px;

		h3{
			letter-spacing: .05em;

			a{
				border: none;
				color: #000;
			}
		}

		p{
			line-height: 1.2em;
			min-height: 3.6em;
		}
	}
}
	.speaker-info{
		@include clearfix;;
		font-size: 18px;

		h3{
			//@extend %serif;
			font-size: 30px;
			letter-spacing: .05em;
			line-height: 1em;
			margin: 5px 0 5px;

			a{
				color: #000;
				border: none;

				&:hover{
					color: $blue;
				}
			}
		}
		.position{
			font-size: 16px;
			letter-spacing: .05em;
			text-transform: uppercase;
			margin: 0 0 .75em;
		}
		.twitter{
			float: left;
			font-size: 16px;
			font-style: italic;
			letter-spacing: .05em;
			margin: 0 0 1em 30px;
			position: relative;

			&:before{
				@extend %icon;
				content: $icon-twitter;
				position: absolute;
				top: 0;
				left: -30px;
			}
		}
	}
