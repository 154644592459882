.mobile-only,
#header .links{
	display: none;
}

@mixin breakpoint-xs{
	h1{
		font-size: 30px;
	}

	h1, h2, h3{
		small{
			display: block;
		}
	}

	hr{
		margin: 15px 0;
	}

	.mobile-only,
	#header .links{
		display: block;
	}




	// ul{
	// 	border-top: 1px solid #dfdfdf;
	//
	// 	li{
	// 		padding: 10px 0;
	// 		border-bottom: 1px solid #dfdfdf;
	//
	// 		&:before{
	// 			content: none;
	// 		}
	// 	}
	// }






	.photo{
		border: none;
		max-width: 370px;
		margin-left: auto;
		margin-right: auto;
	}

	.item-nav{
		display: none;
	}

	.speakers{
		.speaker{
			h3{
				font-size: 16px;
				line-height: 1.2em;
				height: 2.4em;
			}
			p{
				display: none;
			}
		}

		.photo:hover:after{
			box-shadow: none;
		}
	}
		.speaker-info{
			.twitter{
				float: none;
			}
		}

	.schedule-nav{
		padding: 10px 0 0;
	}
		.day-toggle{
			float: none;
			display: block;
			margin: 0 0 10px;
			letter-spacing: 0;
		}
	.schedule-day{
		text-align: center;

		h2{
			border-bottom: 1px solid;
			margin: 0;
			padding: 0 0 20px;

			small{
				display: block;
				line-height: 1em;

				&:before{
					content: none;
				}
			}
		}
	}
		.schedule-events{
			margin: 0 0 30px;

			&, tr, td{
				display: block;
				width: 100% !important;
			}
			tr{
				padding: 20px 0;
				border-bottom: 1px solid;
			}
			td{
				padding: 0;
				border-bottom: none;
				text-align: center;
			}
			.event-image{
				display: none;
			}
			.event-time,
			.event-info{
				border-top: none;
				padding-left: 10px;
				padding-right: 10px;
			}
			.event-time{
				margin: 0 0 5px;
			}
		}

	.event-preview.talk .button,
	.event-preview.workshop .button{
		color: #fff;
		text-align: center;
	}

	.event-preview.talk .button{
		background: $blue;
	}

	.event-preview.workshop .button{
		background: $red;
	}

	.single .event{
		.summary{
			.button{
				margin-top: 15px;
			}
		}
		.type{
			margin-top: -80px;
			margin-bottom: 80px;
		}
	}

	.events{
		hr{
			margin: 30px 15px;
		}
	}

	.sponsors{
		margin-bottom: 50px;

		.photo{
			background: #f8f8f8;
		}

		.sponsor-summary{
			p{
				margin: 0 0 15px;
			}
		}
	}

	table.tickets{
		display: none;
	}

	.ticket-list{
		border-top: 1px solid;
		padding-top: 15px;

		h3{
			font-size: 16px;
			margin: 0 0 10px;

			strong{
				display: block;
				font-size: 22px;
				margin: 0 0 5px;
			}
		}
		ul{
			margin: 0 0 15px;
			padding-bottom: 15px;
			border-top: none;
			border-bottom: 1px solid;

			li{
				padding: 0;
				border: none;
			}
		}
		em{
			display: block;
			color: $red;
			font-size: .8em;
			font-style: normal;
			font-weight: bold;
			text-transform: uppercase;
		}
	}

	#sponsors{
		margin-bottom: -20px;

		hr{
			margin: 0 0 30px;
		}
	}

	#footer{
		.social{
			margin-left: -5px;
		}
		.links{
			display: none;
		}
	}

	.page-about img.alignnone{
		margin: 0 auto;
	}
}
