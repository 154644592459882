.diamonds {
  margin-top: 100px;
  margin-bottom: 25px;
    [class*=' col-']{
      padding: 0 8px;

        .diamonds-title {
          background: $red;
        }

        .diamonds-item {
          display: block;
          padding: 0;
          left: -25%;
          top: -25%;
          width: 150%;
          overflow: hidden;
          position: relative;
          box-shadow: none;

          .diamonds-item-text {
            @include ratio;
            text-align: center;

            .vcenter-flex > * {
              padding: 40px;
              margin: 0;
            }
          }

          &.photo {
            img {
              filter: grayscale(100%);
            }
            .diamonds-item-text {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              opacity: 0;
            }

            &:hover .diamonds-item-text {
              opacity: 1;
            }
          }

          &.diamonds-button {
            transition: all .3s ease-out;
            * {
              color: darken($red,15%);
            }
            &:hover {
              background: $red;
              * { color: white; }
            }
          }


        }
    }
    .col-6 {
        width: 34.5%;
        margin: -16% 7.7% -7.7% 7.7%;
    }
    .col-6:nth-child(3n){
        margin-left: 33%;
    }


}

@include xs-screen {
  .diamonds {
    .diamonds-title * {
      font-size: 20px;
    }
    .diamonds-button h3 {
      font-size: 20px;
      padding: 40px 20px !important;
    }
  }
}


@include sm-screen-min{
    .diamonds{
        [class*=' col-']{ padding: 0 10px; }
        .col-sm-4 {
            width: 23%;
            margin: -11% 5% -5% 5%;
        }
        .col-6:nth-child(3n){
            margin-left: 5%;
        }
        .col-sm-4:nth-child(5n + 4){
            margin-left: 21.5%;
        }
    }
}

@include md-screen-min {
    .diamonds{
      margin-top: 0px;
        [class*=' col-']{ padding: 5px; }

        .col-md-3 {
            width: 17%;
            margin: -8.5% 4% -4% 4%;
        }

        .col-md-3:nth-child(1){
          margin-left: 16.5%;
          margin-bottom: -3%;
        }

        .col-md-3:nth-child(2){
          clear: left;
        }

        .col-6:nth-child(3n), .col-sm-4:nth-child(5n + 4){
            margin-left: 4%;
        }

        .col-md-3:nth-child(7n + 6){
            margin-left: 16.5%;
        }
        .col-md-3:nth-child(9n){
            margin-left: 29%;
        }
    }
}
