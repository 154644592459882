$topbar-height: 40px;

.site-utility {
	color: #fff;
	background: $dark;
	padding: 7px 0;
	font-size: 15px;
	font-weight: 600;
	letter-spacing:.05em;
	text-align: center;
	z-index: 4;
	height: $topbar-height - 10px;

	p{
		margin: 0;
		line-height: 1em;
	}

	a, a:visited {
		color: $white;
		&:hover, &:active {
			color: $red;
		}
	}

	@include sm-screen-min {
		height: $topbar-height;
		padding: 12px 0;
		text-align: right;
	}
}




.site-header {
	position: absolute;
	left: 0;
	right: 0;
	z-index: 3;
	color: #fff;
	text-transform: uppercase;
	background: rgba($dark,.6);

	@include xs-screen {
		font-size: 16px;
		.container, .row{
			padding: 0;
			margin: 0;
		}
	}

	.logo {
		font: 0/0 a;
		width: 60px;
		height: 50px;
		background: url(../img/roq-logo.png) center center no-repeat;
		background-image: url(../img/roq-logo.svg);
		background-size: contain;

		@include xs-screen {
			margin-left: -5px;
		}

		// @include sm-screen-min {
		// 	width: 187px;
		// 	height: 85px;
		// }

		@include md-screen-min {
			width: 110px;
			height: 110px;
		}
		@include xl-screen {
			width: 122px;
			height: 122px;
		}
	}
}
