.sponsor-tile{
	width: 100%;
	text-align: center;
	margin-bottom: 30px;
	transition: box-shadow .1s;

	@include xs-screen {
		margin-bottom: 10px;
	}

	a{
		display: block;
		border: none;
	}
		img{
			max-width: 90%;
			max-height: 90%;
			width: auto;
			height: auto;
			margin: 0 auto;
			vertical-align: middle;
		}

	&.small{
		height: 170px * .5;

		a {
			line-height: 170px * .5;
		}

		@include xs-screen {
			height: 170px * .25;

			a {
				line-height: 170px * .25;
			}
		}
	}
	&.medium{
		height: 270px * .5;

		a {
			line-height: 270px * .5;
		}
	}
	&.large{
		height: 370px * .5;

		a {
			line-height: 370px * .5;
		}
	}
}
.sponsors{
	margin-bottom: 100px;

	h2{
		border-bottom: 1px solid;
		padding-bottom: 10px;
		margin-bottom: 20px;
	}
}
	.sponsor{
		@include clearfix;;

		.photo{
			background: #fff;
			border: none;
			box-shadow: 0 2px 1px rgba(#c5c5c5, .75);
			margin: 0 0 30px;
			transition: box-shadow .1s;

			&:hover{
				box-shadow: 0 4px 2px rgba(#c5c5c5, .75);
			}
		}

		.website{
			font-size: 16px;
			line-height: 1em;
			font-style: italic;
			letter-spacing: .05em;
			margin: 0 0 1em 20px;
			position: relative;

			&:before{
				@extend %icon;
				content: $icon-link;
				position: absolute;
				top: 5px;
				left: -20px;
			}
		}
	}
		.sponsor-info{
			@include clearfix;;
			font-size: 18px;

			h3{
				//@extend %serif;
				font-size: 24px;
				letter-spacing: .05em;
				line-height: 1em;
				margin: 5px 0 10px;

				a{
					color: #000;
					border: none;

					&:hover{
						color: $blue;
					}
				}
			}
		}

@include lg-screen {
	.sponsor-tiles > .col-lg-2 {
		width: 20%;
	}
}
