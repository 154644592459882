.post-listing {
  .post {
    margin-bottom: 30px;
    min-height: 220px;

    @include md-screen-min {
      min-height: 270px;
    }
  }
}
