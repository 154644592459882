.quote {
	margin: 1rem 0;

	@include md-screen-min {
		margin: 2rem 0;
	}

  &-photo {
    margin-right: 10px;
  	width: 40px
  }

  &-title {
    font-size: 1em;
    margin: 0;
  }

  &-subtitle {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
  }

  &-content {
    font-family: "Open sans", sans-serif;
    padding-right: 1rem;
    font-size: .9em;

		@include md-screen-min {
			font-size: 1.2em;
		}

  }
}
