$black:        #070707;
$white:        #fff;
$grey:         #d1d1d1;
$grey-dark:    #231F20;

// Brand colors
$blue:         #00c6ff;
$red:          #e84960;
$purple:       #BD3494;

// Additional colors
$orange:       hsl(14,  100%, 53%);
$yellow:       hsl(48,  100%, 67%);
$green:        hsl(141, 71%,  48%);
$turquoise:    hsl(171, 100%, 41%);

// Utility colors
$info:         $blue;
$success:      $green;
$warning:      $yellow;
$danger:       $red;

$light:        $white;
$dark:         $grey-dark;
$light-invert: $dark;
$dark-invert:  $light;

$text:         $dark;
$text-invert:  $white;
$border:       $grey;

$info-invert:    findColorInvert($info);
$success-invert: findColorInvert($success);
$warning-invert: findColorInvert($warning);
$danger-invert:  findColorInvert($danger);

$colors: (white: ($white, $black), black: ($black, $white), light: ($light, $light-invert), dark: ($dark, $dark-invert), info: ($info, $info-invert), success: ($success, $success-invert), warning: ($warning, $warning-invert), danger: ($danger, $danger-invert)) !default

// Layout
$xl-screen: 1392px;
